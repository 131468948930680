<template>
  <div>

    <div class="vx-row">

      <!-- Notifications -->
      <div class="vx-col w-full md:w-2/2 mb-base">
        <vx-card title="Notificaciones por email">
          <div slot="no-body" class="p-6 pb-0">

            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label>¿Recibir notificaciones semanales?</label>
              </div>
            </div>

            <div class="vx-row mb-6">
              <div class="vx-col w-full  space-after">
                <vs-switch v-model="notiState" @click="weeklyEmail(notiState)">
                  <template #on>
                    Recibir
                  </template>
                  <template #off>
                    No recibir
                  </template>
                </vs-switch>
              </div>
            </div>

          </div>
        </vx-card>
      </div>

      <!-- Billing details -->
      <div class="vx-col w-full md:w-2/2 mb-base">
        <vx-card title="Datos de facturación">
          <div slot="no-body" class="p-6 pb-0">
            <div class="vx-row mb-6">
              <div class="vx-col w-full  space-after">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-8 col-md-3">
                      <label for="billName">Empresa:</label>
                      <b-form-input id="billName" type="text" v-model="billName" placeholder="Empresa" />
                    </div>
                    <div class="col-sm-8 col-md-3"><label for="billCif">NIF:</label>
                      <b-form-input id="billCif" type="text" v-model="billCif" placeholder="CIF" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8 col-md-4"><label for="billAddress">Dirección:</label>
                      <b-form-input id="billAddress" type="text" v-model="billAddress" placeholder="Dirección" />
                    </div>
                    <div class="col-sm-8 col-md-2"><label for="billZip">CP:</label>
                      <b-form-input id="billZip" type="text" v-model="billZip" placeholder="CP" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8 col-md-2"><label for="billCity">Ciudad:</label>
                      <b-form-input type="text" id="billCity" v-model="billCity" placeholder="Ciudad" />
                    </div>
                    <div class="col-sm-8 col-md-2"><label for="billProvince">Provincia:</label>
                      <b-form-input type="text" id="billProvince" v-model="billProvince" placeholder="Provincia" />
                    </div>
                    <div class="col-sm-8 col-md-2"><label for="billCountry">País:</label>
                      <b-form-input id="billCountry" type="text" v-model="billCountry" placeholder="País" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-8 col-md-6">
                      <b-button class="btn-campaign-filter" variant="outline-secondary" @click="saveBillData()">Guardar</b-button>
                    </div>
                    <div v-if="billErrors" class="col-sm-8 col-md-6">
                      <b-alert show variant="warning">Es obligatorio rellenar todos los campos</b-alert>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </vx-card>
      </div>

      <!-- Notifications -->
      <div class="vx-col w-full md:w-2/2 mb-base">
        <vx-card title="Baja del usuario">
          <div slot="no-body" class="p-6 pb-0">
            <div class="vx-row mb-6">
              <div class="col-sm-12 w-full space-after">
                <div class="container-fluid">
                  <div class="row justify-content-center">
                    <p class="col-sm-12 col-md-6 text-center">Si desea dar de baja su cuenta de ClickDefense puede hacerlo a través del siguiente botón.</p>
                  </div>
                  <div class="row justify-content-center">
                    <vs-button @click="deleteModal">Solicitar baja</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>

    </div>
    <b-modal id="deleteAccount" ref="deleteAccount" :visible="deleteAccount" centered>
      <template v-slot:modal-title>
        <img src="../assets/images/logo/clickdefense-logo-small-dark.png" width="40%">
      </template>
      <h3 class="text-center font-weight-bold">¡Atención!</h3>
      <p class="my-4 text-center">
        Si pulsa en el botón de eliminar, su cuenta en ClickDefense quedará eliminada completamente y no podrá volver a acceder a ella para ver sus datos.
        Acuerdesé de eliminar los privilegios de administración que le concedió a ClickDefense en su cuenta de Google Ads una vez haya eliminado su cuenta en ClickDefense.
      </p>
      <template v-slot:modal-footer>
        <b-button @click="finalDeleteAccount" size="sm" variant="outline-light" class="startButton">
          <span class="text-decoration-none text-white font-weight-bold">Eliminar cuenta</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      billName: '',
      billCif: '',
      billZip: '',
      billCountry: 'España',
      billProvince: '',
      billAddress: '',
      billCity: '',
      billErrors: false,
      notiState: true,
      deleteAccount: false,
    }
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
    },
    showNoti(text, title, color, time) {
      this.$vs.notify({
        title: title,
        text: text,
        color: color || 'primary',
        position: 'top-center',
        time: time || 8000
      })
    },
    saveBillData() {
      this.billErrors = {}
      let counter = 0
      if (this.billName === '') {
        this.billErrors = true
        counter++
      }
      if (this.billCif === '') {
        this.billErrors = true
        counter++
      }
      if (this.billZip === '') {
        this.billErrors = true
        counter++
      }
      if (this.billCountry === '') {
        this.billErrors = true
        counter++
      }
      if (this.billProvince === '') {
        this.billErrors = true
        counter++
      }
      if (this.billCity === '') {
        this.billErrors = true
        counter++
      }
      if (this.billAddress === '') {
        this.billErrors = true
        counter++
      }
      if (counter === 0) {
        this.billErrors = false
        Amplify.Auth.currentSession().then(apiSession => {
          let billData = {
            billingDetails: {
              name: this.billName,
              cif: this.billCif,
              zip: this.billZip,
              country: this.billCountry,
              province: this.billProvince,
              address: this.billAddress,
              city: this.billCity,
            }
          }
          this.$axios.put(`/user/${this.$store.state.user.id}`, billData, {
            headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
          }).then(response => {
            if (response.status === 200) {
              this.$store.dispatch('updateBillInformation', true)
              this.$store.dispatch('updateBillData', billData.billingDetails)
              this.showNoti('Datos guardados correctamente.', 'Datos Actualizados', 'success', 8000)
            }
          }).catch(() => {
            this.showNoti('No se pudieron guardar los datos. Inténtelo más tarde', 'Ocurrió un error', 'danger', 8000)
          })
        }).catch(() => {
          this.showNoti('No se pudieron guardar los datos. Inténtelo más tarde', 'Ocurrió un error', 'danger', 8000)
        })
      }
    },
    weeklyEmail(noti) {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get user
        const notiParams = {
          "use.notiEnable": !noti
        }
        this.$axios.put(`/user/${this.$store.state.user.id}`, notiParams, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            this.$store.dispatch('updateNotiEnable', this.notiState)
            this.$vs.loading.close()
          }
        }).catch(() => {
          this.$vs.loading.close()
        })
        // FIN Get user
      }).catch(() => {
        this.$vs.loading.close()
      })
    },
    deleteModal() {
      this.deleteAccount = true
      this.$refs['deleteAccount'].show()
    },
    finalDeleteAccount() {
      Amplify.Auth.currentSession().then(apiSession => {
        this.$axios.delete(`/user/${this.$store.state.user.id}/${apiSession.accessToken.jwtToken}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.msg === "OK") {
              this.showFarewell()
              this.logOut()
            } else {
              this.showFail()
            }
          }
        }).catch(() => { })
      })
    },
    showFarewell() {
      this.showNoti('Su cuenta en ClickDefense fue eliminada correctamente, ahora ya no podrá acceder más a su panel de control.', 'Cuenta eliminada correctamente', 'success', 15000)
    },
    showFail() {
      this.showNoti('Pongase en contacto con soporte@clickdefense.io porfavor.', 'No ha sido posible eliminar su cuenta', 'danger', 15000)
    },
    logOut() {
      Amplify.Auth.signOut({ global: true })
        .then(() => this.$router.push({ path: '/auth' }), this.$store.dispatch("emptyUser", false), this.$acl.change('user'))
        .catch(() => this.$router.push({ path: '/auth' }), this.$store.dispatch("emptyUser", false), this.$acl.change('user'))
    }
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('update-billingDetails', (billingDetails) => {
      this.billName = billingDetails.name
      this.billCif = billingDetails.cif
      this.billZip = billingDetails.zip
      this.billCountry = billingDetails.country
      this.billProvince = billingDetails.province
      this.billAddress = billingDetails.address
      this.billCity = billingDetails.city
    })
    EventBus.$on('update-notiEnable', (notiEnable) => {
      this.notiState = notiEnable
    })
  },
}
</script>
<style scoped>
.space-after {
  margin-bottom: 1.5rem;
}
</style>
<style>
#deleteAccount___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
}
#deleteAccount___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#deleteAccount___BV_modal_header_ > button {
  color: white;
}
#deleteAccount___BV_modal_body_ {
  background-color: #fff;
  color: gray;
}
#deleteAccount___BV_modal_title_ {
  color: gray;
}
.startButton {
  background-color: #2f1b7a;
}
.startButton:hover {
  background-color: #6556d7;
}
.startButton:hover a {
  color: #212529 !important;
}
.activeChild button.vs-tabs--btn {
  outline: none;
}
.container-fluid > div > div > label {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.7);
}
</style>