var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-2/2 mb-base" },
          [
            _c("vx-card", { attrs: { title: "Notificaciones por email" } }, [
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col w-full" }, [
                      _c("label", [
                        _vm._v("¿Recibir notificaciones semanales?")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full  space-after" },
                      [
                        _c("vs-switch", {
                          on: {
                            click: function($event) {
                              return _vm.weeklyEmail(_vm.notiState)
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "on",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                  Recibir\n                "
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "off",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                  No recibir\n                "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.notiState,
                            callback: function($$v) {
                              _vm.notiState = $$v
                            },
                            expression: "notiState"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-2/2 mb-base" },
          [
            _c("vx-card", { attrs: { title: "Datos de facturación" } }, [
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col w-full  space-after" }, [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-3" },
                            [
                              _c("label", { attrs: { for: "billName" } }, [
                                _vm._v("Empresa:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  id: "billName",
                                  type: "text",
                                  placeholder: "Empresa"
                                },
                                model: {
                                  value: _vm.billName,
                                  callback: function($$v) {
                                    _vm.billName = $$v
                                  },
                                  expression: "billName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-3" },
                            [
                              _c("label", { attrs: { for: "billCif" } }, [
                                _vm._v("NIF:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  id: "billCif",
                                  type: "text",
                                  placeholder: "CIF"
                                },
                                model: {
                                  value: _vm.billCif,
                                  callback: function($$v) {
                                    _vm.billCif = $$v
                                  },
                                  expression: "billCif"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-4" },
                            [
                              _c("label", { attrs: { for: "billAddress" } }, [
                                _vm._v("Dirección:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  id: "billAddress",
                                  type: "text",
                                  placeholder: "Dirección"
                                },
                                model: {
                                  value: _vm.billAddress,
                                  callback: function($$v) {
                                    _vm.billAddress = $$v
                                  },
                                  expression: "billAddress"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-2" },
                            [
                              _c("label", { attrs: { for: "billZip" } }, [
                                _vm._v("CP:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  id: "billZip",
                                  type: "text",
                                  placeholder: "CP"
                                },
                                model: {
                                  value: _vm.billZip,
                                  callback: function($$v) {
                                    _vm.billZip = $$v
                                  },
                                  expression: "billZip"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-2" },
                            [
                              _c("label", { attrs: { for: "billCity" } }, [
                                _vm._v("Ciudad:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "billCity",
                                  placeholder: "Ciudad"
                                },
                                model: {
                                  value: _vm.billCity,
                                  callback: function($$v) {
                                    _vm.billCity = $$v
                                  },
                                  expression: "billCity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-2" },
                            [
                              _c("label", { attrs: { for: "billProvince" } }, [
                                _vm._v("Provincia:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "billProvince",
                                  placeholder: "Provincia"
                                },
                                model: {
                                  value: _vm.billProvince,
                                  callback: function($$v) {
                                    _vm.billProvince = $$v
                                  },
                                  expression: "billProvince"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-2" },
                            [
                              _c("label", { attrs: { for: "billCountry" } }, [
                                _vm._v("País:")
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  id: "billCountry",
                                  type: "text",
                                  placeholder: "País"
                                },
                                model: {
                                  value: _vm.billCountry,
                                  callback: function($$v) {
                                    _vm.billCountry = $$v
                                  },
                                  expression: "billCountry"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-8 col-md-6" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-campaign-filter",
                                  attrs: { variant: "outline-secondary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveBillData()
                                    }
                                  }
                                },
                                [_vm._v("Guardar")]
                              )
                            ],
                            1
                          ),
                          _vm.billErrors
                            ? _c(
                                "div",
                                { staticClass: "col-sm-8 col-md-6" },
                                [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "warning" } },
                                    [
                                      _vm._v(
                                        "Es obligatorio rellenar todos los campos"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-2/2 mb-base" },
          [
            _c("vx-card", { attrs: { title: "Baja del usuario" } }, [
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "col-sm-12 w-full space-after" }, [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c(
                              "p",
                              { staticClass: "col-sm-12 col-md-6 text-center" },
                              [
                                _vm._v(
                                  "Si desea dar de baja su cuenta de ClickDefense puede hacerlo a través del siguiente botón."
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c(
                              "vs-button",
                              { on: { click: _vm.deleteModal } },
                              [_vm._v("Solicitar baja")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          ref: "deleteAccount",
          attrs: {
            id: "deleteAccount",
            visible: _vm.deleteAccount,
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/logo/clickdefense-logo-small-dark.png"),
                      width: "40%"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "startButton",
                      attrs: { size: "sm", variant: "outline-light" },
                      on: { click: _vm.finalDeleteAccount }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-decoration-none text-white font-weight-bold"
                        },
                        [_vm._v("Eliminar cuenta")]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("h3", { staticClass: "text-center font-weight-bold" }, [
            _vm._v("¡Atención!")
          ]),
          _c("p", { staticClass: "my-4 text-center" }, [
            _vm._v(
              "\n      Si pulsa en el botón de eliminar, su cuenta en ClickDefense quedará eliminada completamente y no podrá volver a acceder a ella para ver sus datos.\n      Acuerdesé de eliminar los privilegios de administración que le concedió a ClickDefense en su cuenta de Google Ads una vez haya eliminado su cuenta en ClickDefense.\n    "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }